import {React} from 'react';
import {
  Box,
  Avatar,
  Typography
} from '@mui/material';
import { 
  FormProvider
} from "react-hook-form";
import { 
  Copyright 
} from './Copyright';

export const Form = ({
    icon,
    title,
    methods,
    children
}) => {
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods} >
        {icon &&
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            {icon}
          </Avatar>
        }
      
        <Typography component="h1" variant="h5">
          {title}
        </Typography>

      <Box 
        component="form" 
        noValidate 
        onSubmit={handleSubmit} 
        sx={{ mt: 1 }}
      >
        {children}
      </Box>

      <Copyright sx={{ mt: 5 }} />
    </FormProvider>
  );
}