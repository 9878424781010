import { 
  Route, 
  Routes 
} from 'react-router-dom';
import { 
  Table 
} from './Table';

export const GroupsRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Table />} />
    </Routes>
  );
};