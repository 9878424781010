import {React, useState} from 'react';
import {
  Box,
} from '@mui/material';
import { 
  AppBar,
  Sidebar
} from 'components';

export const MainLayout = ({
  children
}) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar 
        open={open}
        onClick={handleDrawerOpen}
      />

      <Sidebar
        open={open}
        onClick={handleDrawerClose}
      >
        {children}
      </Sidebar>
    </Box>
  );
}
