import create from 'zustand'

export const useAddDialog = create((set) => ({
    open: false,
    setOpen: (open) => set(() => ({ open }))
}));

export const useEditDialog = create((set) => ({
    id: "",
    open: false,
    setOpen: (open) => set(() => ({ open }))
}));