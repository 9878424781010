import { React } from 'react';
import { 
  TextField,
  Autocomplete,
  Skeleton
} from '@mui/material';
import { 
  Controller,
  useFormContext
} from 'react-hook-form';

export const Combo = ({
  name,
  label,
  loading = false,
  ...props
}) => {
  const { control } = useFormContext();

  const Component = () => (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: {ref, onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          {...props}
          id={name}
          disableCloseOnSelect
          loading={true}
          loadingText="Cargando..."
          value={
            typeof value === "string"
            ? props.options.find(i => i.id === value)
            : (value || null)
          }
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => onChange(v.id)}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          renderInput={({ inputProps, ...params }) => (
            <TextField 
              {...params}
              variant='filled'
              label={label}
              inputRef={ref}
              error={!!error}
              helperText={error?.message}
              inputProps={{ ...inputProps, readOnly: true }}
            />
          )}
        />
      )}
    />
  );

  return (
    <>
    {loading
      ?
        <Skeleton>
          <Component />
        </Skeleton>
      
      :
        <Component />
    }
    </>
  );
}