const storagePrefix = 'dashboard_';

export const setStorage = (val) => {
  window.localStorage.setItem(`${storagePrefix}remember`, val);
}

const getStorage = () => {
  return window.localStorage.getItem(`${storagePrefix}remember`) === "true"
    ? window.localStorage
    : window.sessionStorage;
}

export const getValue = (id) => {
  const val = getStorage().getItem(`${storagePrefix}${id}`);
  return  val != "undefined" ? JSON.parse(val) : null;
}

export const setValue = (id, token) => {
  getStorage().setItem(`${storagePrefix}${id}`, JSON.stringify(token));
}

export const clearValue = (id) => {
  getStorage().removeItem(`${storagePrefix}${id}`);
}

export const clearAll = () => {
  getStorage().clear();
}