import {React, useEffect} from 'react';
import { 
    Dashboard as DashboardUI 
} from 'components';
import { 
  Grid,
  Box,
  CircularProgress
} from '@mui/material';
import { 
  useDashboard 
} from '../api/dashboard';
import { 
  useParams 
} from "react-router-dom";


export const Dashboard = () =>  {
  const { id } = useParams();
  const dashboardQuery = useDashboard(id);

  if(dashboardQuery.isFetching){
    return (
      <Box sx={{ 
        display: 'flex',
        height: '70vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <CircularProgress size="5rem" />
      </Box>
    );
  }

  return (
    <Grid height="85vh">

      {dashboardQuery.isFetched &&
        <DashboardUI
            url={dashboardQuery.data}
        />
      }
    </Grid>
  );
}