import {React, useEffect} from 'react';
import yup from "utils/yup";
import { 
    Box,
    Grid,
} from '@mui/material';
import { 
    Text, 
    Dialog,
    MultiCombo
} from 'components';
import { 
    useForm,
    FormProvider
} from "react-hook-form";
import { 
    yupResolver 
} from "@hookform/resolvers/yup";
import { 
    useDashboards 
} from 'features/dashboards/api/dashboard';
import { 
    useUpdateGroup,
    useGroup
} from '../api/group';
import { 
    useEditDialog 
} from '../stores/dialog';

const schema = yup.object().shape({
    name: yup
    .string()
    .required()
    .min(4)
    .max(200),
    description: yup
    .string()
    .required()
    .min(4)
    .max(200)
});

export const EditForm = () =>  {
    const { id, open, setOpen } = useEditDialog();
    const updateGroupMutation = useUpdateGroup();
    const dashboardsQuery = useDashboards({
        config: {
            enabled: open
        }
    });
    const groupQuery = useGroup(id, {
        config: {
            enabled: open
        }
    });
    

    const methods = useForm({
        resolver: yupResolver(schema),
    });
    const { handleSubmit, reset } = methods;

    const onSubmit = async (data) => {
        const dashboards = data.dashboards.map(i => i.id);
        const values = {
            ...data,
            dashboards
        };
        await updateGroupMutation.mutateAsync({
            id,
            data: values
        });
    }
    const onClick = handleSubmit(onSubmit);

    useEffect(() => {
        if(!open){
            reset();
            groupQuery.remove();
        }
    }, [open]);

    useEffect(() => {
        reset(groupQuery.data);
    }, [groupQuery.isFetched]);

    return (
        <Dialog
            title={"Editar Grupo"}
            open={open}
            cancelDisabled={updateGroupMutation.isLoading}
            acceptDisabled={groupQuery.isFetching}
            loading={updateGroupMutation.isLoading}
            onClick={onClick}
            onClose={() => setOpen(false)}
        >
            <FormProvider {...methods} >
                <Box 
                    component="form" 
                    noValidate 
                    onSubmit={handleSubmit}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Text
                                name="name"
                                label="Nombre"
                                disabled
                                loading={groupQuery.isFetching}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Text 
                                name="description"
                                label="Descripción"
                                loading={groupQuery.isFetching}
                            />
                        </Grid>    

                        <Grid item xs={12}>
                            <MultiCombo 
                                label="Dashboards"
                                name="dashboards"
                                options={dashboardsQuery.data ?? []}
                                loading={
                                    dashboardsQuery.isFetching || 
                                    groupQuery.isFetching
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
            </FormProvider>
        </Dialog>
    );
}