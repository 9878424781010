import {React, useEffect, useState} from 'react';
import yup from "utils/yup";
import { 
    Box,
    Grid
} from '@mui/material';
import { 
    Text, 
    Password,
    Dialog,
    MultiCombo,
    Switch
} from 'components';
import { 
    useForm,
    FormProvider
} from "react-hook-form";
import { 
    yupResolver 
} from "@hookform/resolvers/yup";
import { 
    useUpdateUser,
    useUser
} from '../api/user';
import { 
    useGroups 
} from 'features/groups/api/group';
import { 
    useEditDialog 
} from '../stores/dialog';

const schema = yup.object().shape({
    password: yup
    .string()
    .required()
    .min(8)
    .max(200)
    .password()
});

const schemaless = yup.object().shape({});

export const EditForm = () => {

    const [changePass, setChangePass] = useState(false);
    const { id, open, setOpen } = useEditDialog();
    const updateUserMutation = useUpdateUser();
    const groupsQuery = useGroups({
        config: {
            enabled: open
        }
    });
    const userQuery = useUser(id, {
        config: {
            enabled: open
        }
    });

    const methods = useForm({
        resolver: yupResolver(changePass ? schema : schemaless)
    });
    const { handleSubmit, reset, watch } = methods;
    const watchChangePassword = watch("changePassword", false);

    const onSubmit = async (data) => {
        const groups = data.groups.map(i => i.id);
        const values = {
            ...data,
            groups,
            password: changePass ? data.password : null
        };
        await updateUserMutation.mutateAsync({
            id,
            data: values
        });
    }
    const onClick = handleSubmit(onSubmit);

    useEffect(() => {
        if(!open){
            reset();
            userQuery.remove();
        }
    }, [open]);

    useEffect(() => {
        reset(userQuery.data);
    }, [userQuery.isFetched]);

    useEffect(() => {
        setChangePass(watchChangePassword);
    }, [watchChangePassword]);

    return (

        <Dialog
            title={"Editar Usuario"}
            open={open}
            cancelDisabled={updateUserMutation.isLoading}
            acceptDisabled={userQuery.isFetching}
            loading={updateUserMutation.isLoading}
            onClick={onClick}
            onClose={()=> setOpen(false)}
        >
            <FormProvider {...methods} >
                <Box 
                    component="form" 
                    noValidate 
                    onSubmit={handleSubmit} 
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Text                       
                                name="email"
                                label="Email"
                                disabled
                                loading={userQuery.isFetching}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <MultiCombo
                                name="groups"
                                label="Grupos"
                                options={groupsQuery.data ?? []}
                                loading={
                                    groupsQuery.isFetching || 
                                    userQuery.isFetching
                                }
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Switch 
                                name="enabled"
                                label="Habilitado"
                                loading={userQuery.isFetching}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Switch 
                                name="admin"
                                label="Administrador"
                                loading={userQuery.isFetching}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Switch 
                                name="mfa"
                                label="MFA"
                                disabled={!userQuery.data?.mfa}
                                loading={userQuery.isFetching}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Switch 
                                name="changePassword"
                                label="Cambiar la contraseña"
                                loading={userQuery.isFetching}
                            />
                            {watchChangePassword &&
                                <Password 
                                    name="password"
                                    label="Contraseña"
                                    loading={userQuery.isFetching}
                                /> 
                            }
                        </Grid>
                        
                    </Grid>
                </Box>
            </FormProvider>
        </Dialog>
    );
}