import {axios} from "lib/axios";
import jwtDecode from "jwt-decode";
import { 
    useMutation 
} from "@tanstack/react-query";
import {
    history
} from "lib/reactRouter";
import { 
    useNotification 
} from "stores/notification";
import * as storage from "utils/storage";

const setTokens = (data) => {
    storage.setValue("id_token", data.idToken);
    storage.setValue("access_token", data.accessToken);
    storage.setValue("refresh_token", data.refreshToken);
    const decoded = jwtDecode(data.idToken);
    storage.setValue("claims", decoded);
    history.push("/dashboards");
};

const login  = (data) => {
    return axios.post('/auth/login', data);
};

export const useLogin = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            if(!data?.session){
                setTokens(data);
            }
            else{
                history.push("/login_mfa", {
                    session: data?.session
                });
            }
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: login,
    });
};

const loginMFA  = (data) => {
    return axios.post('/auth/login_mfa', data);
};

export const useLoginMFA = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            setTokens(data);
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: loginMFA,
    });
};

export const getRefreshToken = (data) => {
    return axios.post('/auth/refresh_token', data);
};

const forgotPass = (data) => {
    return axios.post('/auth/forgot_pass', data);
};

export const useForgotPass = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
            history.push("/reset", {
                email: storage.getValue("email")
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: forgotPass,
    });
};

const resetPass = (data) => {
    return axios.put('/auth/reset_pass', data);
};

export const useResetPass = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
            history.push("/login");
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: resetPass,
    });
};

const associateMfa = (data) => {
    return axios.put('/auth/associate_mfa', data);
};

export const useAssociateMfa = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: associateMfa,
    });
};