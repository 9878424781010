import {React} from 'react';
import yup from "utils/yup";
import {
  Button
} from '@mui/material';
import { 
  useForm,
} from "react-hook-form";
import {
  Text,
} from "components";
import { 
  yupResolver 
} from "@hookform/resolvers/yup";
import { 
  useNavigate 
} from 'react-router-dom';
import { 
  LoadingButton 
} from '@mui/lab';
import { 
  useForgotPass 
} from '../api/auth';
import { 
  Form 
} from './Form';
import { 
  Key 
} from '@mui/icons-material';
import { 
  setValue 
} from 'utils/storage';

const schema = yup.object().shape({
  email: yup
  .string()
  .required()
  .email()
  .min(8)
  .max(200)
});

export const RecoverPassForm = () =>  {

  const navigate = useNavigate();
  const forgetPassMutation = useForgotPass();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setValue("email", data.email);
    await forgetPassMutation.mutateAsync(data);
  }

  return (
    <Form
      title="Recuperar Contraseña"
      icon={<Key />}
      methods={methods}
    >
      <Text 
          name="email"
          label={"Email"}
      />

      <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit(onSubmit)}
          loading={forgetPassMutation.isLoading}
          type="submit"
      >
          Enviar código
      </LoadingButton>

      <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => navigate("/login")}
          color="error"
      >
          Cancelar
      </Button>
    </Form>
  );
}