import {React} from 'react';
import { 
    Typography,
    Box,
    Paper,
    Skeleton
} from '@mui/material';
import { 
    CheckCircle,
    Cancel
} from '@mui/icons-material';


export const Status = ({
    enabled = false,
    loading = false
}) =>  {

    const Component = () => (
        <Paper 
            elevation={5}
            sx={{
                padding: "0.7rem",
                maxWidth: "fit-content"
            }}
        >
            <Box
            sx={{
                    display: "flex",
                    gap: "1rem",
                }}
            >   
                {enabled
                    ? <CheckCircle style={{ color: "green" }} />
                    : <Cancel style={{ color: 'red' }} />
                }
                <Typography>
                    MFA se encuentra 
                    {enabled ? " habilitado" : " deshabilitado"}
                </Typography>
            </Box>
        </Paper>
    );

    return (
        <>
            {
            loading
            ?
                <Skeleton>
                    <Component />
                </Skeleton>
            :
                <Component />
            }
        </>
    );
}