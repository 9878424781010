import {React} from 'react';
import { 
    Stack,
    Snackbar as SnackbarUI,
    Alert,
    Slide
} from '@mui/material';
import { 
    useNotification 
} from 'stores/notification';

const SlideTransition = (props) => {
    return <Slide {...props} direction="up" />; 
}

export const Snackbar = () => {
    const { 
        open,
        severity,
        message,
        setOpen
    } =  useNotification();

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <SnackbarUI 
                open={open} 
                autoHideDuration={5000} 
                onClose={() => setOpen(false)}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ 
                    horizontal: "center", 
                    vertical: "bottom" 
                }}
            >
                <Alert 
                    severity={severity}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </SnackbarUI>
        </Stack>
    );
};