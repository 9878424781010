import { 
  Suspense 
} from 'react';
import { 
  Outlet,
  Navigate
} from 'react-router-dom';
import { 
  Snackbar,
  MainLayout
} from 'components';
import { 
  UsersRoutes
} from 'features/users/routes';
import { 
  GroupsRoutes
} from 'features/groups/routes';
import { 
  DashboardRoutes 
} from 'features/dashboards/routes';
import { 
  SettingsRoutes 
} from 'features/settings/routes';
import { 
  NotFound 
} from 'features/misc/routes';
import { 
  Auth 
} from 'components/Auth';


const App = () => {
  return (
    <MainLayout>
      <Suspense fallback="Ha ocurrido un error">
        <Snackbar />
        <Auth>
          <Outlet />
        </Auth>
      </Suspense>
    </MainLayout>
  );
};

export const ProtectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/users/*', element: <UsersRoutes /> },
      { path: '/groups/*', element: <GroupsRoutes /> },
      { path: '/dashboards/*', element: <DashboardRoutes /> },
      { path: '/settings/*', element: <SettingsRoutes /> },
      { path: '/', element: <Navigate to="/dashboards" replace /> },
      { path: '*', element: <NotFound /> },
    ]
  },
];
