import React, {useState} from 'react';
import { 
  Typography 
} from '@mui/material';

export const NotFound = () => {
    return (
      <Typography variant="h6">
        Esta página no existe
      </Typography>
    );
  };