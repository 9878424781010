import {React} from 'react';
import { 
  useTheme 
} from '@mui/material/styles';
import {
  Box,
  List,
  Divider,
  IconButton,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Logout
} from '@mui/icons-material';
import { 
  SubMenu 
} from './SubMenu';
import { 
  DrawerHeaderUI,
  DrawerUI 
} from './Sidebar.style';
import { 
  Link
} from 'components';
import { 
  clearAll 
} from 'utils/storage';
import {
  history
} from "lib/reactRouter";
import { 
  getValue 
} from 'utils/storage';
import { 
  SubMenus 
} from 'data/menus';

export const Sidebar = ({
  children,
  onClick,
  open
}) => {
  const theme = useTheme();

  const { 
    "custom:role": role
  } = getValue("claims");
  const isAdmin = role !== "user";

  const handleLogout = () => {
    clearAll();
    history.push("/login")
  };

  return (
    <>
      <DrawerUI variant="permanent" open={open}>

        <DrawerHeaderUI>
          <IconButton onClick={onClick}>
            {theme.direction === 'rtl' 
              ? <ChevronRight /> 
              : <ChevronLeft />
            }
          </IconButton>
        </DrawerHeaderUI>

        <List>
          {SubMenus.map((i, c) => {
              if(i.admin === isAdmin || !i.admin){
                return (
                  <Link key={c} to={i.path}>
                      <SubMenu
                        open={open}
                        text={i.text}
                        icon={i.icon}
                        path={i.path}
                      />
                  </Link>
                )
              }
          })}
          <Divider />

          <SubMenu
            open={open}
            text={"Cerrar sesion"}
            icon={<Logout />}
            onClick={handleLogout}
          />
          
        </List>
      </DrawerUI>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeaderUI />
        {children}
      </Box>
    </>
  );
}
