import {React} from 'react';
import { 
    Switch as SwitchUI,
    Skeleton,
    Typography
} from '@mui/material';
import {
    Controller,
    useFormContext
} from "react-hook-form";

export const Switch = ({
    label,
    name,
    loading = false,
    ...props
}) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
                loading
                ?
                    <Skeleton height="100%">
                        <Switch name="" />
                    </Skeleton>
                :
                    <>
                        <Typography component="div">
                            {label}
                        </Typography>
                        <SwitchUI
                            {...props}
                            onChange={onChange}
                            checked={value}
                        />
                    </>
            )}
        />
    );
};