import {React} from 'react';
import { 
  Grid,
  CircularProgress,
  Box,
  Grow,
  Typography
} from '@mui/material';
import { 
  GridItem 
} from '../components/GridItem';
import { 
  Link 
} from 'components';
import { 
  useDashboards 
} from 'features/users/api/user';

export const DashboardGrid = () =>  {

  const dashboardsQuery = useDashboards();

  if(dashboardsQuery.isFetching){
    return (
      <Box sx={{ 
        display: 'flex',
        height: '70vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <CircularProgress size="5rem" />
      </Box>
    );
  }

  
  if((dashboardsQuery.data ?? []).length === 0){
    return (
      <Typography variant="h5">
        No tienes ningún dashboard asignado
      </Typography>
    );
  }

  return (
    <Grid container spacing={4}>
      {(dashboardsQuery.data ?? []).map((i, c) => (
        <Grow
          in={true}
          style={{ transformOrigin: '0 0 0' }}
          timeout={1000}
          key={c}
        >
          <Grid item xs={12} sm={6} md={4} lg={3}>
              <Link to={`/dashboards/${i.id}`}>
                  <GridItem
                    id={i.id}
                    name={i.name}
                    createdTime={new Date(i.createdTime).toLocaleDateString("es-MX")}
                    lastUpdatedTime={new Date(i.lastUpdatedTime).toLocaleDateString("es-MX")}
                  />
              </Link>
          </Grid>
          </Grow>
      ))}
      
    </Grid>
  );
}