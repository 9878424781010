import {React, useEffect} from 'react';
import yup from "utils/yup";
import {
  Link as LinkMUI,
  Grid,
} from '@mui/material';
import { 
  useForm
} from "react-hook-form";
import {
  Password,
  Text,
  Link,
  Switch
} from "components";
import { 
  Dashboard 
} from '@mui/icons-material';
import { 
  yupResolver 
} from "@hookform/resolvers/yup";
import { 
  Form 
} from './Form';
import { 
  LoadingButton 
} from '@mui/lab';
import { 
  useLogin 
} from '../api/auth';
import * as storage from 'utils/storage';

const schema = yup.object().shape({
  email: yup
  .string()
  .required()
  .email(),
  password: yup
  .string()
  .required()
});

export const LoginForm = () =>  {

  const loginMutation = useLogin();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      remember: true
    }
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    storage.setStorage(data.remember);
    storage.setValue("email", data.email);
    await loginMutation.mutateAsync(data);
  }

  useEffect(() => {
    storage.clearAll();
  }, []);

  return (
    <Form
      title="Dashboard"
      icon={<Dashboard />}
      methods={methods}
    >
      <Text 
          name="email"
          label={"Email"}
      />

      <Password 
          name="password"
          label={"Contraseña"}
      />

      <Switch 
          name="remember"
          label="Recordar"
      />

      <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit(onSubmit)}
          loading={loginMutation.isLoading}
          type="submit"
      >
          Iniciar Sesión
      </LoadingButton>

      <Grid container>
          <Grid item xs>
              <Link to={"/recover"}>
                <LinkMUI component="div" variant="body2">
                    ¿Olvidaste tu Contraseña?
                </LinkMUI>
              </Link>
          </Grid>
      </Grid>
      
    </Form>
  );
}