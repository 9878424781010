import {axios} from "lib/axios";
import { 
    useMutation,
    useQuery
} from "@tanstack/react-query";
import { 
    useNotification 
} from "stores/notification";
import { 
    queryClient 
} from "lib/reactQuery";

const getMFA = (accessToken) => {
    return axios.get("/settings/mfa", { params: {accessToken}});
};

export const useMFA = (accessToken, {config} = {}) => {
    return useQuery({
        ...config,
        queryKey: ["mfa"],
        queryFn: () => getMFA(accessToken),
    });
};

const updatePass = (data) => {
    return axios.put("/settings/update_pass", data);
};

export const useUpdatePass = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: updatePass,
        });
};

const associateMFA = (data) => {
    return axios.put("/settings/associate_mfa", data);
};

export const useAssociateMFA = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            useNotification.setState({
                open: true,
                severity: "success",
                message: "Se ha generado exitosamente"
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: associateMFA,
    });
};

const verifyMFA = (data) => {
    return axios.put("/settings/verify_mfa", data);
};

export const useVerifyMFA = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries(["mfa"]);
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: verifyMFA,
    });
};

const disableMFA = (data) => {
    return axios.put("/settings/disable_mfa", data);
};

export const useDisableMFA = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: disableMFA,
    });
};