import { React } from 'react';
import { 
    DataGrid as DataGridUI,
    esES
} from '@mui/x-data-grid';


export const DataGrid = ({
  rows, 
  columns,
  ...props
}) => {
  return (
    <DataGridUI
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      //experimentalFeatures={{ newEditingApi: true }}
      {...props}
    />
  );
}
