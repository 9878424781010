import { React } from "react";
import { 
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import { 
    useLocation 
} from "react-router-dom";

export const SubMenu = ({
    open,
    text,
    icon,
    path,
    ...props
}) => {
    const { pathname } = useLocation();
    const selected = path === pathname;

    return (
        <ListItem
            {...props}
            disablePadding 
            sx={{ display: 'block' }}
        >
            <ListItemButton
            sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                backgroundColor: selected && "#d8d8d8"
            }}
            >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
        </ListItem>
    );
}