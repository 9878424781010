import { 
  Route, 
  Routes,
  Navigate
} from 'react-router-dom';
import { 
  LoginForm,
  LoginMFAForm,
  RecoverPassForm,
  ResetPassForm
} from '../components';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginForm />} />
      <Route path="login_mfa" element={<LoginMFAForm />} />
      <Route path="recover" element={<RecoverPassForm />} />
      <Route path="reset" element={<ResetPassForm />} />
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};