import {React} from "react";
import {
  CssBaseline,
  Paper,
  Grid,
  Box
} from "@mui/material";
import honne from "assets/honne.png"

export const PublicLayout = ({
    children
}) => {

  return (
    <Grid 
      container 
      component="main" 
      sx={{ height: "100vh" }}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${honne})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "80%",
          backgroundPosition: "center",
        }}
      />
      <Grid 
        item xs={12} 
        sm={8} 
        md={5} 
        component={Paper} 
        elevation={6} 
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}