import { 
  Route, 
  Routes 
} from 'react-router-dom';
import { 
  DashboardGrid 
} from './DashboardGrid';
import { 
  Dashboard 
} from './Dashboard';

export const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path=":id" element={<Dashboard />} />
      <Route path="*" element={<DashboardGrid />} />
    </Routes>
  );
};