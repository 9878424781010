import {React, useEffect} from 'react';
import yup from "utils/yup";
import { 
    Box,
    Grid
} from '@mui/material';
import { 
    Text, 
    Password,
    Dialog,
    MultiCombo,
    Switch
} from 'components';
import { 
    useForm,
    FormProvider
} from "react-hook-form";
import { 
    yupResolver 
} from "@hookform/resolvers/yup";
import { 
    useCreateUser 
} from '../api/user';
import { 
    useGroups 
} from 'features/groups/api/group';
import { 
    useAddDialog 
} from '../stores/dialog';

const schema = yup.object().shape({
    email: yup
        .string()
        .required()
        .email()
        .min(8)
        .max(200),
    password: yup
        .string()
        .required()
        .min(8)
        .max(200)
        .password()
});

export const AddForm = () => {

    const { open, setOpen } = useAddDialog();
    const createUserMutation = useCreateUser();
    const groupsQuery = useGroups({
        config: {
            enabled: false
        }
    });
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            enabled: true
        }
    });

    const { handleSubmit, reset } = methods;
    const onSubmit = async (data) => {
        const groups = data.groups.map(i => i.id);
        const values = {
            ...data,
            groups
        };
        await createUserMutation.mutateAsync(values);
    }
    const onClick = handleSubmit(onSubmit);

    useEffect(() => {
        if(open){
            groupsQuery.refetch();
        }
        else{
            reset();
        }
    }, [open]);
    
    return (

        <Dialog
            open={open}
            title={"Nuevo Usuario"}
            cancelDisabled={createUserMutation.isLoading}
            loading={createUserMutation.isLoading}
            onClick={onClick}
            onClose={() => setOpen(false)}
        >
            <FormProvider {...methods} >
                <Box 
                    component="form" 
                    noValidate 
                    onSubmit={handleSubmit} 
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Text 
                                name="email"
                                label="Email"
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <MultiCombo
                                name="groups"
                                label="Grupos"
                                loading={groupsQuery.isFetching}
                                options={groupsQuery.data ?? []}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Switch 
                                name="enabled"
                                label="Habilitado"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Switch 
                                name="admin"
                                label="Administrador"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Password 
                                name="password"
                                label="Contraseña"
                            />
                        </Grid>
 
                    </Grid>
                </Box>
            </FormProvider>
        </Dialog>
    );
}