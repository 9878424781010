import {React} from 'react';
import { 
    Navigate 
} from 'react-router-dom';
import { 
    SubMenus 
} from 'data/menus';
import { 
    useLocation 
} from 'react-router-dom';
import { 
    getValue 
} from 'utils/storage';

export const Auth = ({
    children
}) => {
    const location = useLocation();
    const { "custom:role": role } = getValue("claims");
    const isAdmin = role !== "user";
    const menu = SubMenus.find(i => i.path === location.pathname);
    
    if(menu?.admin === isAdmin || !menu?.admin){
        return children;
    }
    else{
        return <Navigate to="/dashboards" replace />;
    }
};