import {React, useState} from 'react';
import {
    TextField,
    InputAdornment,
    IconButton,
    Skeleton
} from '@mui/material';
import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import {
    Controller,
    useFormContext
} from "react-hook-form";

export const Password = ({
    name,
    label,
    loading = false
}) => {
    const { control } = useFormContext();
    const [show, setShow] = useState(false);

    const handleClickShowPassword = () => {
        setShow(i => !i);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };
    
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                loading
                ?
                    <Skeleton width="100%" height="100%">
                        <TextField 
                            fullWidth 
                            margin="normal" 
                            variant='filled' 
                        />
                    </Skeleton>
                :
                    <TextField
                        margin="normal"
                        fullWidth
                        variant='filled'
                        name="password"
                        label={label}
                        type={show ? 'text' : 'password'}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        InputProps={{
                            endAdornment:(
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {show ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off',
                            },
                        }}
                    />
            )}
        />
    );
};