import {React} from 'react';
import { 
  Paper,
  Skeleton
} from '@mui/material';
import { 
  useLogo 
} from '../api/orgs';

export const Logo = () => {

  const logoQuery = useLogo();

  return (
    <Paper
      sx={{
        height: "3rem",
        width: "8rem"
      }}
    >
      {logoQuery.isLoading
        ? 
          <Skeleton width="100%" height="100%" />
        :
          <img 
              src={logoQuery.data}
              width="100%" height="100%"
          />
      }
    </Paper>
  );
}