import { AxiosError } from 'axios';
import { QueryClient } from '@tanstack/react-query';

const queryConfig = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({ 
  defaultOptions: queryConfig 
});