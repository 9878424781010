import React, {useEffect} from "react";
import { 
    embedDashboard 
} from 'amazon-quicksight-embedding-sdk';
import { 
    styled 
} from '@mui/material/styles';

const Container = styled('div')({
    height: '100%'
});

export const Dashboard = ({
    url
}) => {

    useEffect(() => {
        var options = {
            url: url,
            container: document.getElementById("quicksight"),
            //scrolling: "yes",
            //height: "100%",
            //loadingHeight: "700px",
            //width: "100%",
            locale: "es-ES",
            footerPaddingEnabled: true,
            //iframeResizeOnSheetChange: true,
        };

        embedDashboard(options); 
    }, []);

    return (
        <Container id="quicksight" />
    );
};