import { 
  AuthRoutes 
} from "features/auth/routes";
import { 
  Snackbar,
  PublicLayout
} from "components";

const App = () => {
  return (
    <PublicLayout>
        <Snackbar />
        <AuthRoutes />
    </PublicLayout>
  );
};

export const PublicRoutes = [
  {
    path: '/*',
    element: <App />,
  },
];