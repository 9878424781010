import { 
    CircularProgress,
    Button 
} from '@mui/material';
import { 
  styled 
} from '@mui/material/styles';

export const ButtonUI = styled(Button)({
  height: '40px',
});

export const LoadButton = ({
  children,
  loading = false,
  ...props
}) => {

  return (
    <Button {...props}>
      {children}
      {!loading &&
        <CircularProgress 
          style={{ color: 'white' }}
          size={20}
        />
      }
    </Button>
  );
}