import {React} from 'react';
import yup from "utils/yup";
import {
  Button
} from '@mui/material';
import { 
  useForm,
} from "react-hook-form";
import {
  Password,
  Text,
} from "components";
import { 
  yupResolver 
} from "@hookform/resolvers/yup";
import { 
  useNavigate,
  useLocation,
  Navigate
} from 'react-router-dom';
import { 
  useResetPass 
} from '../api/auth';
import { 
  LoadingButton 
} from '@mui/lab';
import { 
  Form 
} from './Form';
import { 
  Update 
} from '@mui/icons-material';


const schema = yup.object().shape({
  code: yup
  .string()
  .required()
  .min(6)
  .max(6),
  password: yup
  .string()
  .required()
  .min(8)
  .max(200)
  .password()
});

export const ResetPassForm = () =>  {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state ?? {email: null};
  const resetPassMutation = useResetPass();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;
  const onSubmit = async (data) => {
    const values = {
      ...data,
      email
    }
    await resetPassMutation.mutateAsync(values);
  }

  if(email == null){
    return <Navigate to="/login" replace />
  }

  return (
    <Form
      title="Actualizar Password"
      icon={<Update />}
      methods={methods}
    >
      <Text 
          name="code"
          label={"Código"}
      />

      <Password 
          name="password"
          label={"Nuevo Password"}
      />

      <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit(onSubmit)}
          loading={resetPassMutation.isLoading}
          type="submit"
      >
          Actualizar
      </LoadingButton>

      <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => navigate("/login")}
          color="error"
      >
          Cancelar
      </Button>
    </Form>
  );
}