import {React} from 'react';
import {
    TextField,
    Skeleton
} from '@mui/material';
import {
    Controller,
    useFormContext
} from "react-hook-form";

export const Text = ({
    name,
    label,
    loading = false,
    ...props
}) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                loading
                ?   
                    <Skeleton width="100%" height="100%">
                        <TextField 
                            fullWidth 
                            margin="normal" 
                            variant='filled' 
                        />
                    </Skeleton>
                :
                    <TextField
                        margin="normal"
                        fullWidth
                        variant='filled'
                        label={label}
                        name={name}
                        autoFocus
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        InputProps={{
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off',
                            },
                        }}
                        {...props}
                    />
            )}
        />
    );
};