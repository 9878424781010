import { React } from 'react';
import { 
    Checkbox,
    TextField,
    Autocomplete,
    Skeleton
} from '@mui/material';
import { 
    CheckBox,
    CheckBoxOutlineBlank
} from '@mui/icons-material';
import { 
  Controller,
  useFormContext
} from 'react-hook-form';

export const MultiCombo = ({
  name,
  label,
  loading = false,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: {ref, onChange, value }, fieldState: { error } }) => (
        loading
        ? 
          <Skeleton width="100%" height="100%">
              <TextField 
                fullWidth 
                margin="normal" 
                variant='filled' 
              />
          </Skeleton>
        :
          <Autocomplete
            {...props}
            multiple
            id={name}
            disableCloseOnSelect
            loading={true}
            loadingText="Cargando..."
            value={value}
            getOptionLabel={(option) => option.name}
            onChange={(e, v) => onChange(v)}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderOption={(params, option, { selected }) => (
                <li {...params}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
            )}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label={label}
                variant='filled'
                inputRef={ref}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
      )}
    />

  );
}