import {React} from 'react';
import {
  Tabs
} from "components";
import { 
  ChangePassForm 
} from '../components/ChangePassForm';
import { 
  MFAForm 
} from '../components/MFAForm';

const tabs = [
  {
    title: "Cambiar Contraseña",
    component: <ChangePassForm />
  },
  {
    title: "MFA",
    component: <MFAForm />
  }
];

export const Main = () =>  {
  return (
    <Tabs items={tabs} />
  );
}