import {React, useEffect, useState} from 'react';
import yup from "utils/yup";
import { 
    Box,
    Grid,
    Paper
} from '@mui/material';
import { 
    useForm,
    FormProvider
} from "react-hook-form";
import { 
    yupResolver 
} from "@hookform/resolvers/yup";
import { 
    LoadingButton 
} from '@mui/lab';
import { 
    getValue 
} from 'utils/storage';
import { 
    useAssociateMFA,
    useVerifyMFA,
    useMFA,
    useDisableMFA
} from '../api/settings';
import { 
    Text
} from 'components';
import { 
    Status 
} from './Status';

const schema = yup.object().shape({
    code: yup
    .string()
    .required()
    .min(6)
    .max(6)
});

export const MFAForm = () =>  {

    const accessToken = getValue("access_token");
    const asocciateMFAMutation = useAssociateMFA();
    const verifyMFAMutation = useVerifyMFA();
    const disableMFAMutation = useDisableMFA();
    const mfaQuery = useMFA(accessToken);
    const [disabledMfa, setDisabledMfa] = useState(true);
    const methods = useForm({
        resolver: yupResolver(schema)
    });
    const { handleSubmit, reset } = methods;

    const handleAssociate = async () => {
        const values = {
            accessToken
        };
        await asocciateMFAMutation.mutateAsync(values);
    }

    const handleDisable = async () => {
        await disableMFAMutation.mutateAsync();
        asocciateMFAMutation.reset();
        mfaQuery.refetch();
    }

    const handleVerify = async (data) => {
        const values = {
            ...data,
            accessToken: getValue("access_token")
        }
        await verifyMFAMutation.mutateAsync(values);
    }

    useEffect(() => {
        if(asocciateMFAMutation.data != null){
            setDisabledMfa(false);
        }
    }, [asocciateMFAMutation.isSuccess]);

    useEffect(() => {
        if(verifyMFAMutation.isSuccess){
            reset();
            setDisabledMfa(true);
        }
    }, [verifyMFAMutation.isSuccess]);

    return (
        <FormProvider {...methods} >
            <Box 
                component="form" 
                noValidate 
                onSubmit={handleSubmit} 
            >
                <Grid item container spacing={2} xs={12} sm={4}>

                    <Grid item xs={12}>
                        <Status 
                            enabled={mfaQuery.data?.mfa}
                            loading={mfaQuery.isLoading 
                                || mfaQuery.isFetching
                            }
                        />
                    </Grid>

                    {mfaQuery.isSuccess && mfaQuery.data?.mfa &&
                        <Grid item xs={12}>
                            <LoadingButton 
                                variant="contained"
                                onClick={handleDisable}
                                loading={disableMFAMutation.isLoading}
                            >
                                Deshabilitar MFA
                            </LoadingButton>
                        </Grid>
                    }

                    {mfaQuery.isSuccess && !mfaQuery.data?.mfa &&
                        <>
                        <Grid item xs={12}>
                            <LoadingButton 
                                variant="contained"
                                onClick={handleAssociate}
                                loading={asocciateMFAMutation.isLoading}
                            >
                                Generar QR
                            </LoadingButton>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={5} sx={{ width: "280px" }}>
                                <Box 
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <img 
                                        src={asocciateMFAMutation.data}
                                        height="250"
                                    />
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Text
                                name="code"
                                label="Codigo"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton 
                                variant="contained"
                                onClick={handleSubmit(handleVerify)}
                                loading={
                                    verifyMFAMutation.isLoading
                                }
                                disabled={disabledMfa}
                            >
                                Habilitar MFA
                            </LoadingButton>
                        </Grid>
                        </>
                    }
                    
                </Grid>
            </Box>
        </FormProvider>
    );
}