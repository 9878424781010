import {axios} from "lib/axios";
import { 
    useQuery
} from "@tanstack/react-query";

const getOrgs = () => {
    return axios.get('/org');
};

export const useOrgs = ({ config } = {}) => {
    return useQuery({
        ...config,
        queryKey: ['orgs'],
        queryFn: () => getOrgs()
    });
};

const getLogo = () => {
    return axios.get('/logo');
};

export const useLogo = ({ config } = {}) => {
    return useQuery({
        ...config,
        queryKey: ['logo'],
        queryFn: () => getLogo()
    });
};