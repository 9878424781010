import {axios} from "lib/axios";
import { 
    useQuery,
    useMutation
} from "@tanstack/react-query";
import { 
    useNotification 
} from "stores/notification";
import { 
    useAddDialog, 
    useEditDialog 
} from "../stores/dialog";
import { 
    useDeleteDialog 
} from "stores/dialog";
import { 
    queryClient 
} from "lib/reactQuery";

const getUsers = () => {
    return axios.get('/user');
};

export const useUsers = ({config} = {}) => {
    return useQuery({
        ...config,
        queryKey: ['users'],
        queryFn: () => getUsers(),
    });
};

const getDashboards = () => {
    return axios.get('/user/dashboards');
};

export const useDashboards= ({config} = {}) => {
    return useQuery({
        ...config,
        queryKey: ['user_dashboards '],
        queryFn: () => getDashboards(),
    });
};

const createUser = (data) => {
    return axios.post('/user', data);
};

export const useCreateUser = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries(['users']);
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
            useAddDialog.setState({
                open: false
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: createUser,
    });
};

const deleteUsers = (data) => {
    return axios.delete('/user', {data});
};

export const useDeleteUsers = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries(['users']);
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
            useDeleteDialog.setState({
                open: false
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: deleteUsers,
    });
};

const getUser = (id) => {
    return axios.get(`/user/${id}`);
};

export const useUser = (id, {config} = {}) => {
    return useQuery({
        ...config,
        queryKey: ['user', id],
        queryFn: () => getUser(id),
    });
};


const updateUser = ({id, data}) => {
    return axios.put(`/user/${id}`, data);
};

export const useUpdateUser = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries(['users']);
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
            useEditDialog.setState({
                open: false
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: updateUser,
    });
};