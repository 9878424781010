import {React} from 'react';
import {
  Box,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
import {
  Menu
} from '@mui/icons-material';
import { 
  AppBarUI,
} from './AppBar.style';
import { 
  getValue 
} from 'utils/storage';
import { 
  Organizations,
  Logo
} from 'features/orgs/components';

export const AppBar = ({
  open,
  onClick
}) => {

  const { 
    "email": email,
    "custom:role": role,
    "custom:org": org
  } = getValue("claims");

  const isHonneAdmin = role === "admin" && org === "honne";

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBarUI position="fixed" open={open}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onClick}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6">
              Dashboard
          </Typography>

          <Box sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "2rem",
            width: "100%",
          }}
          >
            <Logo />

            {isHonneAdmin &&
              <Organizations />
            }

            <Typography variant="body1">
              {email}
            </Typography>

          </Box>
        </Toolbar>
      </AppBarUI>
    </Box>
  );
}
