import { React } from 'react';
import { 
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { 
  DialogUI,
  CancelButtonUI
} from './Dialog.style';
import { 
  LoadingButton 
} from '@mui/lab';

export const Dialog = ({
  children,
  title,
  cancelDisabled,
  acceptDisabled,
  open,
  onClose,
  onClick,
  loading = false
}) => {
  return (
    <DialogUI open={open} 
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <CancelButtonUI 
          onClick={onClose}
          disabled={cancelDisabled}
          color="error"
          variant="contained"
        >
          Cancelar
        </CancelButtonUI>

        <LoadingButton 
          variant="contained"
          disabled={acceptDisabled}
          onClick={onClick}
          loading={loading}
          type="submit"
        >
          Aceptar
        </LoadingButton>
      </DialogActions>
    </DialogUI>
  );
}
