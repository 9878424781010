import {React, useState} from 'react';
import { 
  Box,
  Tabs as TabsUI,
  Tab
} from '@mui/material';

const TabPanel = ({
    children,
    value,
    index
}) => {
  return (
    <>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </>
  );
}

export const Tabs = ({items}) =>  {

  const [value, setValue] = useState(0);

  const handleChange = (e, val) => {
    setValue(val);
  };

  return (
    <>
    <Box 
      sx={{ 
        borderBottom: 1, 
        borderColor: 'divider' 
      }}
    >
      <TabsUI value={value} onChange={handleChange}>
        {items.map(({title}, c) => (
          <Tab label={title} key={c} />
        ))}
      </TabsUI>
    </Box>

    {items.map(({component}, c) => (
        <TabPanel value={value} index={c} key={c}>
            {component}
        </TabPanel>
    ))}
    </>
  );
}