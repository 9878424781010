import React, {useState} from 'react';
import { 
  DataGrid,
  Actions,
  DeleteDialog
} from 'components';
import { 
  AddForm 
} from '../components/AddForm';
import { 
  styled 
} from '@mui/material/styles';
import { 
  Button
} from '@mui/material';
import { 
  EditForm 
} from '../components/EditForm';
import { 
  LoadingButton 
} from '@mui/lab';
import { 
  useUsers,
  useDeleteUsers
} from '../api/user';
import { 
  useAddDialog,
  useEditDialog
} from '../stores/dialog';
import { 
  useDeleteDialog 
} from 'stores/dialog';

const Columns = [
  {
    field: 'id',
    hide: true
  },
  {
    field: 'user',
    headerName: 'Usuario',
    flex: 2,
  },
  {
    field: 'groups',
    headerName: 'Grupos',
    flex: 4,
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    flex: 1,
    renderCell: (params) => {
      return(
        <Actions 
          onEdit={()=> {
            useEditDialog.setState({
              id: params.id,
              open: true
            });
          }} 
        />
      );
    }
  }
];

const DataGridUI = styled(DataGrid)({
  marginTop: '1rem',
  height: '77vh'
});

const NewUserButton = styled(Button)({
  marginRight: '1rem'
});

export const Table = () =>  {
  const [selected, setSelected] = useState(false);
  const { setOpen: setOpenAdd }  = useAddDialog();
  const { setOpen: setOpenDel }  = useDeleteDialog();
  const usersQuery = useUsers();
  const deleteUsersMutation = useDeleteUsers();
  
  const handleDelete = async () =>{
    const values = {
      emails: selected
    };
    await deleteUsersMutation.mutateAsync(values);
  };

  return (
    <>
      <NewUserButton 
        variant="contained" 
        onClick={() => setOpenAdd(true)}
      >
          Nuevo Usuario
      </NewUserButton>

      <LoadingButton 
        variant="contained"
        color="error"
        disabled={!selected.length > 0}
        onClick={() => setOpenDel(true)}
      >
          Eliminar seleccionados
      </LoadingButton>

      <DataGridUI
        loading={usersQuery.isFetching}
        columns={Columns}
        rows={usersQuery.data ?? []}
        checkboxSelection={!usersQuery.isFetching}
        onSelectionModelChange={(data) => {
          setSelected(data);
        }}
      />

      <DeleteDialog 
        title="Eliminar Usuarios"
        loading={deleteUsersMutation.isLoading}
        cancelDisabled={deleteUsersMutation.isLoading}
        onClick={handleDelete}
      >
        ¿Está seguro de que desea eliminar los usuarios seleccionados?
        <br />
        Una vez eliminados no podrán restaurarse.
      </DeleteDialog>

      <AddForm />
      
      <EditForm />
    </>
  );
}