import Axios from 'axios';
import { API_URL } from 'config';
import * as storage from 'utils/storage';
import {
  history
} from "lib/reactRouter";
import { 
  getRefreshToken 
} from 'features/auth/api/auth';


function authRequestInterceptor(config) {
  const id_token = storage.getValue("id_token");
  const org = storage.getValue("org");
  if (id_token) {
    config.headers.authorization = id_token;
    config.headers["organization"] = org;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if(error.response?.status === 400){
      storage.clearAll();
      history.push("/login");
    }
    else if(error.response?.status === 401){
      const refreshToken = storage.getValue("refresh_token");
      const resp = await getRefreshToken({refreshToken});
      storage.clearAll();
      storage.setValue("id_token", resp.idToken);
      storage.setValue("access_token", resp.accessToken);
      storage.setValue("refresh_token", resp.refreshToken);
      return axios(error.config);
    }

    return Promise.reject(error);
  }
);
