import {React, useEffect} from 'react';
import yup from "utils/yup";
import { 
    Box,
    Grid,
} from '@mui/material';
import { 
    Text, 
    Dialog,
    MultiCombo
} from 'components';
import { 
    useForm,
    FormProvider
} from "react-hook-form";
import { 
    yupResolver 
} from "@hookform/resolvers/yup";
import { 
    useDashboards 
} from 'features/dashboards/api/dashboard';
import { 
    useCreateGroup 
} from '../api/group';
import { 
    useAddDialog 
} from '../stores/dialog';

const schema = yup.object().shape({
    name: yup
    .string()
    .required()
    .lowercase()
    .min(4)
    .max(200),
    description: yup
    .string()
    .required()
    .min(4)
    .max(200)
});

export const AddForm = () =>  {
    
    const { open, setOpen } = useAddDialog();
    const createGroupMutation = useCreateGroup();
    const dashboardsQuery = useDashboards({
        config: {
            enabled: false
        }
    });

    const methods = useForm({
        resolver: yupResolver(schema),
    });
    const { handleSubmit, reset } = methods;
    
    const onSubmit = async (data) => {
        const dashboards = data.dashboards.map(i => i.id);
        const values = {
            ...data,
            dashboards
        };
        await createGroupMutation.mutateAsync(values);
    }
    const onClick = handleSubmit(onSubmit);

    useEffect(() => {
        if(open){
            dashboardsQuery.refetch();
        }
        else{
            reset();
        }
    }, [open]);

    return (
        <Dialog
            title={"Nuevo Grupo"}
            open={open}
            cancelDisabled={createGroupMutation.isLoading}
            loading={createGroupMutation.isLoading}
            onClick={onClick}
            onClose={() => setOpen(false)}
        >
            <FormProvider {...methods} >
                <Box 
                    component="form" 
                    noValidate 
                    onSubmit={handleSubmit}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Text 
                                name="name"
                                label="Nombre"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Text 
                                name="description"
                                label="Descripción"
                            />
                        </Grid>    

                        <Grid item xs={12}>
                            <MultiCombo 
                                label="Dashboards"
                                name="dashboards"
                                loading={dashboardsQuery.isFetching}
                                options={dashboardsQuery.data ?? []}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </FormProvider>
        </Dialog>
    );
}