import { React, useEffect } from 'react';
import { 
    ErrorBoundary 
} from 'react-error-boundary';
import { 
    unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import {
  history,
} from 'lib/reactRouter';
import { 
  QueryClientProvider 
} from '@tanstack/react-query';
import { 
  queryClient 
} from 'lib/reactQuery';
import { 
  ReactQueryDevtools 
} from '@tanstack/react-query-devtools';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
    </div>
  );
};

export const AppProvider = ({ 
    children 
}) => {
  
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
        <HistoryRouter history={history}>
          {children}
        </HistoryRouter>
    </QueryClientProvider>
  );
};
