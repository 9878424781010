import React from 'react';
import { 
  useRoutes,
} from 'react-router-dom';
import { 
  ProtectedRoutes 
} from './protected';
import { 
  PublicRoutes 
} from './public';
import { 
  getValue 
} from 'utils/storage';

export const AppRoutes = () => {
  const isLogged = () => getValue("id_token") !== null;

  const routes = isLogged() ? ProtectedRoutes : PublicRoutes;
  const element = useRoutes([...routes]);

  return <>{element}</>;
};
