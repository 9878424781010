import {React} from 'react';
import { 
    IconButton 
} from '@mui/material';
import { 
    Edit
} from '@mui/icons-material';
import { 
    styled 
} from '@mui/material/styles';

const IconButtonUI = styled(IconButton)({
    color: 'inherit'
});

export const Actions = ({
    onEdit
}) => {
    return (
        <IconButtonUI onClick={onEdit}>
            <Edit />
        </IconButtonUI>
    );
};