import * as yup from "yup";

yup.setLocale({
  mixed: {
    default: 'Es obligatorio',
    required: 'Es obligatorio',
  },
  string: {
    min: ({ min }) => `Debe ser mayor a ${min} caracteres`,
    max: ({ max }) => `Debe ser menor a ${max} caracteres`,
    email: 'Debe ser un correo valido',
    required: 'Debe ser un correo valido',
  },
  array: {
    min: ({ min }) => `Debe ser mayor a ${min}`,
  }
});

yup.addMethod(yup.string, "password", function (
  errorMessage = "Debe contener uno en mayúscula, uno en minúscula, un número y un carácter especial, por ejemplo: Password1*"
  ) {
  return this.test(`test-password`, errorMessage, function (value) {
    const { path, createError } = this;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s])(?=.{8,})/;
    return (
      (value.match(regex)) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, "lowercase", function (
  errorMessage = "Deben ser solo minúsculas"
  ) {
  return this.test(`test-lowercase`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (!/[A-Z]/.test(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

export default yup;