import {React} from 'react';
import { 
    Link as LinkUI 
} from 'react-router-dom';

export const Link = (props) => {
    return (
        <LinkUI
            {...props}
            style={{ 
                color: 'inherit', 
                textDecoration: 'inherit'
            }}
        />
    );
};