import {React, useEffect} from 'react';
import {
  Combo 
} from 'components';
import { 
  useForm,
  FormProvider
} from "react-hook-form";
import { 
  useOrgs 
} from 'features/orgs/api/orgs';
import * as storage from 'utils/storage';

export const Organizations = () => {
  const methods = useForm({
    defaultValues: {
      org: storage.getValue("org") ?? "honne"
    }
  });
  const orgsQuery = useOrgs();
  const { watch } = methods;
  const org = watch("org");

  useEffect(() => {
    if(storage.getValue("org") !== org){
      storage.setValue("org", org);
      window.location.reload();
    }

  }, [org]);

  return (
    <FormProvider {...methods}>
        <Combo
            name="org"
            label="Organizaciones"
            options={(orgsQuery.data ?? [])}
            loading={orgsQuery.isFetching}
            disableClearable
            sx={{
                width: "300px",
                backgroundColor: "#ffffff"
            }}
        />
    </FormProvider>
  );
}