import {axios} from "lib/axios";
import { 
    useQuery,
    useMutation
} from "@tanstack/react-query";
import { 
    useAddDialog,
    useEditDialog
} from "../stores/dialog";
import { 
    useNotification 
} from "stores/notification";
import { 
    queryClient 
} from "lib/reactQuery";
import { 
    useDeleteDialog 
} from "stores/dialog";

const getGroups = () => {
    return axios.get('/group');
};

export const useGroups = ({ config } = {}) => {
    return useQuery({
        ...config,
        queryKey: ['groups'],
        queryFn: () => getGroups()
    });
};

const createGroup = (data) => {
    return axios.post('/group', data);
};

export const useCreateGroup = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries(['groups']);
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
            useAddDialog.setState({
                open: false
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: createGroup,
    });
};

const deleteGroups = (data) => {
    return axios.delete('/group', {data});
};

export const useDeleteGroups = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries(['groups']);
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
            useDeleteDialog.setState({
                open: false
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: deleteGroups,
    });
};

const getGroup = (id) => {
    return axios.get(`/group/${id}`);
};

export const useGroup = (id, {config} = {}) => {
    return useQuery({
        ...config,
        queryKey: ['group', id],
        queryFn: () => getGroup(id),
    });
};

const updateGroup = ({id, data}) => {
    return axios.put(`/group/${id}`, data);
};

export const useUpdateGroup = ({ config } = {}) => {
    return useMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries(['groups']);
            useNotification.setState({
                open: true,
                severity: "success",
                message: data
            });
            useEditDialog.setState({
                open: false
            });
        },
        onError: (error) => {
            useNotification.setState({
                open: true,
                severity: "error",
                message: error.response.data
            });
        },
        ...config,
        mutationFn: updateGroup,
    });
};