import {React, useState} from 'react';
import {
  AddForm,
  EditForm 
} from '../components';
import { 
  styled 
} from '@mui/material/styles';
import { 
  DataGrid,
  Actions,
  DeleteDialog
} from 'components';
import { 
  Button 
} from '@mui/material';
import { 
  useDeleteDialog
} from 'stores/dialog';
import { 
  useAddDialog,
  useEditDialog
} from '../stores/dialog';
import { 
  useGroups,
  useDeleteGroups
} from '../api/group';

const Columns = [
  {
    field: 'id',
    hide: true
  },
  {
    field: 'name',
    headerName: 'Grupo',
    flex: 2
  },
  {
    field: 'description',
    headerName: 'Descripción',
    flex: 3
  },
  {
    field: 'dashboards',
    headerName: 'Dashboards',
    flex: 8
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    flex: 2,
    renderCell: (params) => {
      return(
        <Actions 
            onEdit={()=> {
              useEditDialog.setState({
                id: params.id,
                open: true
              });
            }} 
          />
      );
    }
  }
];

export const DataGridUI = styled(DataGrid)({
  marginTop: '1rem',
  height: '77vh'
});

const NewUserButton = styled(Button)({
  marginRight: '1rem'
});

export const Table = () =>  {
  const [selected, setSelected] = useState([]);
  const {setOpen: setOpenDel} = useDeleteDialog();
  const {setOpen: setOpenAdd} = useAddDialog();
  const groupsQuery = useGroups();
  const deleteGroupsMutation = useDeleteGroups();

  const handleDelete = async (e) => {
    const values = {
      groups: selected
    };
    await deleteGroupsMutation.mutateAsync(values);
  };

  return (
    <>
      <NewUserButton 
        variant="contained" 
        onClick={() => setOpenAdd(true)}
      >
          Nuevo Grupo
      </NewUserButton>

      <Button 
        variant="contained"
        color="error"
        disabled={!selected.length > 0}
        onClick={() => setOpenDel(true)}
      >
          Eliminar seleccionados
      </Button>

      <DataGridUI
        loading={groupsQuery.isFetching}
        columns={Columns}
        rows={groupsQuery.data ?? []}
        checkboxSelection={!groupsQuery.isFetching}
        onSelectionModelChange={(data) => {
          setSelected(data);
        }}
      />

      <DeleteDialog 
        title="Eliminar Grupos"
        onClick={handleDelete}
        loading={deleteGroupsMutation.isLoading}
        cancelDisabled={deleteGroupsMutation.isLoading}
      >
        ¿Está seguro de que desea eliminar los grupos seleccionados?
        <br />
        Una vez eliminados no podrán restaurarse.
      </DeleteDialog>

      <AddForm />
      
      <EditForm />
    </>
  );
}
