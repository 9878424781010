import {
  Dashboard,
  Groups3,
  Person,
  Settings,
} from '@mui/icons-material';

export const SubMenus = [
    {
      text: "Dashboards",
      icon: <Dashboard />,
      path: "/dashboards",
      admin: false
    },
    {
      text: "Usuarios",
      icon: <Person />,
      path: "/users",
      admin: true
    },
    {
      text: "Grupos",
      icon: <Groups3 />,
      path: "/groups",
      admin: true
    },
    {
      text: "Ajustes",
      icon: <Settings />,
      path: "/settings",
      admin: false
    }
  ];