import { 
    Dialog,
    Button
} from "@mui/material";
import { 
    styled 
} from '@mui/material/styles';

export const DialogUI = styled(Dialog)({
    '& .MuiPaper-root': {
        padding: '1rem'
    }
});

export const CancelButtonUI = styled(Button)({
    marginRight: '2rem'
});