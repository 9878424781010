import { React } from 'react';
import { 
    Grid,
    Paper,
    Typography,
    Grow
} from '@mui/material';
import { 
  styled 
} from '@mui/material/styles';

export const PaperUI = styled(Paper)({
  "transition": "transform 0.15s ease-in-out",
  "&:hover": { transform: "scale3d(1.1, 1.1, 1)" },
});

export const GridItem = ({
  id,
  name,
  createdTime,
  lastPublishedTime,
  lastUpdatedTime
}) => {
  return (
    <PaperUI
      sx={{
        p: 2,
        maxWidth: 300,
        flexGrow: 1,
        cursor: 'pointer'
      }}
      elevation={8}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
            {name}
          </Typography>
          <Typography variant="body2" gutterBottom>
          </Typography>
        </Grid>

        <Grid item>
          
        <Typography variant="body2" color="text.secondary">
            Fecha de Creación: {createdTime}
          </Typography>
        </Grid>

        <Grid item>
          
          <Typography variant="body2" color="text.secondary">
            Fecha de Actualización: {lastUpdatedTime}
          </Typography>
        </Grid>
      </Grid>
    </PaperUI>
  );
}
