import {axios} from "lib/axios";
import { 
    useQuery,
    useMutation
} from "@tanstack/react-query";

const getDashboards = () => {
    return axios.get('/dashboard');
};

export const useDashboards = ({config} = {}) => {
    return useQuery({
        ...config,
        queryKey: ['dashboards'],
        queryFn: () => getDashboards(),
    });
};

const getDashboard = (id) => {
    return axios.get(`/dashboard/${id}`);
};

export const useDashboard = (id, {config} = {}) => {
    return useQuery({
      onSuccess: () => {
      },
      ...config,
      queryKey: ["dashboard", id],
      queryFn: () => getDashboard(id),
    });
};

export const createGroup = (data) => {
    return axios.post('/group', data);
};

export const deleteGroups = (data) => {
    return axios.delete('/group', data);
};

export const getGroup = (id) => {
    return axios.get(`/group/${id}`);
};

export const updateGroup = (id, data) => {
    return axios.put(`/group/${id}`, data);
};