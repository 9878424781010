import {React} from 'react';
import yup from "utils/yup";
import {
  Button
} from '@mui/material';
import { 
  useForm
} from "react-hook-form";
import {
  Text,
} from "components";
import { 
  PhonelinkLock
} from '@mui/icons-material';
import { 
  yupResolver 
} from "@hookform/resolvers/yup";
import { 
  Form 
} from './Form';
import { 
  LoadingButton 
} from '@mui/lab';
import { 
  useLoginMFA
} from '../api/auth';
import { 
  getValue 
} from 'utils/storage';
import { 
  useNavigate,
  useLocation,
  Navigate
} from 'react-router-dom';

const schema = yup.object().shape({
  code: yup
  .string()
  .required()
});

export const LoginMFAForm = () =>  {

  const navigate = useNavigate();
  const loginMutation = useLoginMFA();
  const location = useLocation();
  const { session } = location.state ?? { session: null };

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    const email = getValue("email");
    const values = {
      ...data,
      session,
      email
    };
    await loginMutation.mutateAsync(values);
  }

  if(session == null){
    return <Navigate to="/login" replace />
  }

  return (
    <Form
      title="MFA"
      icon={<PhonelinkLock />}
      methods={methods}
    >
      <Text 
          name="code"
          label="Codigo"
      />

      <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit(onSubmit)}
          loading={loginMutation.isLoading}
          type="submit"
      >
          Aceptar
      </LoadingButton>

      <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => navigate("/login")}
          color="error"
      >
          Cancelar
      </Button>
      
    </Form>
  );
}