import {React, useState} from 'react';
import { 
    Dialog 
} from 'components/Dialog';
import { 
    Typography,
    TextField,
    Grid
} from '@mui/material';
import { 
    useDeleteDialog 
} from 'stores/dialog';
import { 
    Dangerous 
} from '@mui/icons-material';

export const DeleteDialog = ({
    children,
    ...props
}) => {

    const [val, setVal] = useState("");

    const handleChange = (e) => {
        setVal(e.target.value);
    };

    const {open, setOpen} = useDeleteDialog();

    const handleClose = () => {
        setOpen(false);
        setVal("");
    };

    const handleClipboard = (e) => {
        e.preventDefault();
    };

    return (
        <Dialog
            acceptDisabled={val != "eliminar"}
            open={open}
            onClose={handleClose}
            {...props}
        >
            <Grid container justifyContent="center">
                <Dangerous 
                    sx={{ fontSize: 100 }} 
                    color="error" 
                />
            </Grid>

            <Typography align="justify">
                {children}
            </Typography>

            <br />

            <Typography>
                Escribe la palabra "eliminar" para continuar
            </Typography>

            <TextField
                margin="normal"
                fullWidth
                variant='filled'
                name="confirmation"
                autoFocus
                value={val}
                onChange={handleChange}
                onCut={handleClipboard}
                onCopy={handleClipboard}
                onPaste={handleClipboard}
                InputProps={{
                    autoComplete: 'new-password',
                    form: {
                        autoComplete: 'off',
                    },
                }}
            />
        </Dialog>
    );
};