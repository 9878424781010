import {React, useEffect} from 'react';
import yup from "utils/yup";
import { 
    Box,
    Grid,
} from '@mui/material';
import { 
    Password,
} from 'components';
import { 
    useForm,
    FormProvider
} from "react-hook-form";
import { 
    yupResolver 
} from "@hookform/resolvers/yup";
import { 
    LoadingButton 
} from '@mui/lab';
import { 
    getValue 
} from 'utils/storage';
import { 
    useUpdatePass 
} from '../api/settings';

const schema = yup.object().shape({
    password: yup
    .string()
    .required()
    .min(8)
    .max(200),
    newPassword: yup
    .string()
    .required()
    .min(8)
    .max(200)
    .password(),
});

export const ChangePassForm = () =>  {
    const updatePassMutation =  useUpdatePass();
    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit, reset } = methods;

    const onSubmit = async (data) => {
        const values = {
            ...data,
            accessToken: getValue("access_token")
        }
        await updatePassMutation.mutateAsync(values);
    }

    useEffect(() => {
        reset();
    }, [updatePassMutation.isSuccess]);
    
    return (
        <FormProvider {...methods} >
            <Box 
                component="form" 
                noValidate 
                onSubmit={handleSubmit} 
            >   
                <Grid item container spacing={2}  xs={12} sm={4}>

                    <Grid item xs={12}>
                        <Password 
                            name="password"
                            label="Contraseña Actual"
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <Password 
                            name="newPassword"
                            label="Nueva Contraseña"
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <LoadingButton 
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            loading={updatePassMutation.isLoading}
                            type="submit"
                        >
                            Aceptar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
    );
}